import React from 'react'
import { Card, Input, Row, Col } from 'antd'
import { connect } from 'react-redux'
import Icon from '@ant-design/icons'
import actions from 'redux/virtual/stallconfigurator/actions'

/* eslint-disable prefer-promise-reject-errors, consistent-return,prefer-destructuring */

const integrations = {
	facebook: {
		title: 'Facebook',
		icon: 'facebook',
		placeholder: 'https://www.facebook.com/PageHandle',
	},
	instagram: {
		title: 'Instagram',
		icon: 'instagram',
		placeholder: 'https://www.instagram.com/PageHandle',
	},
	linkedin: {
		title: 'Linkedin',
		icon: 'linkedin',
		placeholder: 'https://www.linkedin.com/company/CompanyHandle/',
	},
	twitter: {
		title: 'twitter',
		icon: 'twitter',
		placeholder: 'https://twitter.com/CompanyHandle',
	},
}

@connect(({ stallConfigurator }) => ({ stallConfigurator }))
class SocialMediaForm extends React.Component {

  setInputChange = event => {
    const { dispatch,section, stallConfigurator: {scene} } = this.props
    const name = event.target.getAttribute('data-name')
    const sceneID = Object.keys(scene)[0]
    const newState = scene
    newState[sceneID].clickSections[section[0]].socialMedia[name] = event.target.value
    dispatch({
      type: actions.SET_STATE,
      payload:{
        scene: newState
      }
    })
  }

  render() {
    const {
      section
    } = this.props

    const allInputs = Object.entries(integrations).map(key => {
      const imagebaseURL = 'resources/images/svgicons/'
      const imgURL = `${imagebaseURL}${integrations[key[0]].icon}.svg`
			return (
  <>
    <Row>
      <Col span={24}>
        <Input
          addonAfter={
            <Icon
              style={{ display: 'initial' }}
              component={() => (
                <img
                  src={imgURL}
                  style={{ width: '35px' }}
                  alt={key[1].title}
                />
              )}
            />
          }
          data-index={key[0]}
          placeholder={integrations[key[0]].placeholder}
          onChange={this.setInputChange}
          key={`${key[0]}_url`}
          data-name={key[0]}
          value={section[1].socialMedia[key[0]]}
        />
      </Col>
    </Row>
    <br />
  </>
      )
    })
    return (
      <Card title={section[1].name} style={{ width: "90%" }}>
        {allInputs}
      </Card>
      // <>
      // </>
    )
  }
}

export default SocialMediaForm
