const actions = {
  SET_STATE: 'virtaluser/SET_STATE',
  LOGIN: 'virtaluser/LOGIN',
  LOAD_CURRENT_ACCOUNT: 'virtaluser/LOAD_CURRENT_ACCOUNT',
  LOGOUT: 'virtaluser/LOGOUT',
  LOGIN_HELPER: 'virtualuser/LOGIN_HELPER',
  FORGOT_PASSWORD: 'virtualUser/FORGOT_PASSWORD',
  RESET_PASSWORD: 'virtualUser/RESET_PASSWORD',
  EDIT_AVATAR: 'virtualUser/EDIT_AVATAR',
  COMPLETE_EDIT_PROFILE: 'virtualUser/COMPLETE_EDIT_PROFILE'
}

export default actions
