import React from 'react'
import { message, Card, Upload, Tooltip, Button, Row, Col } from 'antd'
import { connect } from 'react-redux'
import { UploadOutlined } from '@ant-design/icons'
import ReactPlayer from 'react-player'
import actions from 'redux/virtual/stallconfigurator/actions'

/* eslint-disable prefer-promise-reject-errors, consistent-return,prefer-destructuring */

const mediaTypes = {
  "jpg": { mediaType : "image/jpeg", size: 2 },
  "jpeg": { mediaType : "image/jpeg", size: 2 },
  "png": { mediaType : "image/png", size: 2 },
  "gif": { mediaType : "image/gif", size: 2 },
  "pdf": { mediaType : "application/pdf", size: 5 },
  "mp4": { mediaType : "video/mp4", size: 100 },

}
@connect(({ stallConfigurator }) => ({ stallConfigurator }))
class PlaceholderForm extends React.Component {

  beforeUpload = (file) => {
    const {
      section
    } = this.props

    const extension = file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase()

    let fileFormatPermitted = section[1].widget.widgetData.fileFormatPermitted
    if(typeof fileFormatPermitted === "string")
    {
      fileFormatPermitted = fileFormatPermitted.substring(1, fileFormatPermitted.length-1).replace(/ /g, '').split(",");
    }
    const theFilePermittedArray = []
    fileFormatPermitted.forEach(element => {
      if (Object.prototype.hasOwnProperty.call(mediaTypes, element)) {
        theFilePermittedArray.push(mediaTypes[element].mediaType)
      }
    });
    console.log("here to see inside section")
    console.log(section)
    const isPermitted = theFilePermittedArray.includes(file.type)
    if (!isPermitted) {
      message.error(`File format not supported. This section only accepts the following formats: ${section[1].widget.widgetData.fileFormatPermitted.toString()} `);
    }

    let fileSizeAllowed = false
    if (Object.prototype.hasOwnProperty.call(mediaTypes, extension)) {
      fileSizeAllowed = file.size / 1024 / 1024 < mediaTypes[extension].size; // file size should be less than 655kb
      if (!fileSizeAllowed) {
        message.error(`File must smaller than ${mediaTypes[extension].size}mb!!`);
      }
    }
    else{
      message.error(`File format not supported. This section only accepts the following formats: ${section[1].widget.widgetData.fileFormatPermitted.toString()} `);
    }
    
    return isPermitted && fileSizeAllowed;
  }

  fileUpload = ({ file, onSuccess, onProgress, onError, data }) => {
    const { dispatch, currentScene, index } = this.props
    dispatch({
      type: actions.STALL_UPLOAD,
      payload: {
        file,
        clickSectionIndex: index,
        downloadIndex: data,
        uploadtype: 'fileupload',
        onSuccess,
        onError,
        onProgress,
        currentScene,
      },
    })
  }

  render() {
    const {
      section
    } = this.props

    console.log("look at section")
    console.log(section)
    let theViewUrl = ''
    let toShowThumbnail = false
    if(section[1].typeofPod === "video")
    {
      const videoID = Object.keys(section[1].videoData)[0]
      theViewUrl = section[1].videoData[videoID].url
      if(theViewUrl !== "")
      {
        toShowThumbnail = true
      }
    }
    else if(section[1].typeofPod === "widget")
    {
      theViewUrl = section[1].widget.imageUrl
      if(theViewUrl !== "")
      {
        toShowThumbnail = true
      }
    }
    return (
      <Card title={section[1].name} style={{ width: "90%" }}>
        <Row>
          <Col span={18}>
            <p>{section[1].widget.widgetData.extraMessage}</p>
            <Upload 
              beforeUpload={this.beforeUpload}
              customRequest={this.fileUpload}
              data={section[0]}
            >
              <Tooltip title="Upload Asset">
                <Button>
                  <UploadOutlined /> Click to Upload
                </Button>
              </Tooltip>
            </Upload>
          </Col>
          <Col span={6}>
            {section[1].typeofPod === "video" && toShowThumbnail ? 
              <ReactPlayer
                url={theViewUrl}
                light
                playing
                // controls
                loop
                width="100%"
                height="6rem"
                volume={1}
                style={{ backgroundColor: '0 0 0 0.5' }}
              />
            :
              ""
            }
            {section[1].typeofPod === "widget" && toShowThumbnail ? 
              <img src={theViewUrl} alt="actualimage" style={{width: "100%"}} />
            :
              ""
            }
            
          </Col>
        </Row>
      </Card>
      // <>
      // </>
    )
  }
}

export default PlaceholderForm
