import API from '../config/api'

export async function uploadFile(data) {
  const formData = new FormData();
  formData.append("file", data.file);
  return API.post('uploadFile', formData, data.config, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
}

export async function logout() {
    return ""
  }