import React from 'react'
import { Modal, Row, Col } from 'antd'
import { connect } from 'react-redux'
import { CloseCircleOutlined } from '@ant-design/icons'
import { Scrollbars } from 'react-custom-scrollbars'
import actions from 'redux/virtual/stallconfigurator/actions'
import StallDetails from './StallDetails'
import StallSelector from './StallSelector'
import StallCustomizer from './StallCustomizer'

import './stalldesigner.less'

const mapStateToProps = ({ stallConfigurator, virtualUser }) => ({ stallConfigurator, virtualUser })

@connect(mapStateToProps)
class StallDesigner extends React.Component {
  constructor(props) {
    super()
    
    const {virtualUser: {assignedScene}} = props
    if(assignedScene.length > 0)
    {
      props.dispatch({
        type: actions.SET_STATE,
        payload: {
          current: 2,
        },
      })
    }

    this.steps = [
      {
        title: 'Enter Stall Details',
        content: <StallDetails />,
      },
      {
        title: 'Select Your Stall',
        content: <StallSelector />,
      },
      {
        title: 'Customize your stall',
        content: <StallCustomizer />,
      },
    ]
  }

  handleClose = () => {
    const { dispatch } = this.props
    dispatch({
      type: actions.SET_STATE,
      payload: {
        isStallDesignerOpen: false,
      },
    })
  }

  render() {
    const {
      stallConfigurator: { isStallDesignerOpen, current },
    } = this.props
    return (
      <div>
        <Modal
          visible={isStallDesignerOpen}
          style={{
            top: 0,
            display: 'flex',
          }}
          wrapClassName="editProfileModal"
          maskClosable={false}
          mask
          width="80rem"
          onOk={this.handleClose}
          confirmLoading
          onCancel={this.handleClose}
          footer={null}
          maskStyle={{backgroundColor: "rgba(15, 12, 41, 0.93)"}}
          closeIcon={<CloseCircleOutlined style={{ color: 'white', fontSize: '25px' }} />}
          closable
          // destroyOnClose
        >
          <Row id="modalHeader" className="modalHeader text-center">
            <Col span={24} className="text-center">
              <h3>The Stall Configurator</h3>
            </Col>
          </Row>
          <Row id="modalContent" className="modalContent">
            <Scrollbars autoHeight autoHeightMin="100px" autoHeightMax="calc(100vh - 15rem)">
              <Row gutter={16}>
                <Col span={24} className="text-center" style={{margin: "15px 15px 0 15px"}}>
                  <h4>Step {current+1}: {this.steps[current].title}</h4>
                </Col>
              </Row>
              <Col span={24}>
                {this.steps[current].content}
              </Col>
            </Scrollbars>
          </Row>
        </Modal>
      </div>
    )
  }
}

export default StallDesigner
