const actions = {
  SET_STATE: 'virtualEvent/SET_STATE',
  GET_DEFAULT_SCENE: 'virtualEvent/GET_DEFAULT_SCENE',
  FILTER_SCENE: 'virtualEvent/FILTER_SCENE',
  SAVE_BRIEFCASE: 'virtualEvent/SAVE_BRIEFCASE',
  GET_BRIEFCASE: 'virtualEvent/GET_BRIEFCASE',
  REMOVE_FROM_BRIEFCASE: 'virtualEvent/REMOVE_FROM_BRIEFCASE',
  SEND_BRIEFCASE_EMAIL: 'virtualEvent/SEND_BRIEFCASE_EMAIL',
  DOWNLOAD_FILE: 'virtualEvent/DOWNLOAD_FILE',
  SEND_LEAD: 'virtualEvent/SEND_LEAD',
  SET_LEAD: 'virtualEvent/SET_LEAD',
  ASSIGN_CHAIR: 'virtualEvent/ASSIGN_CHAIR',
  LEAVE_CHAIR: 'virtualEvent/LEAVE_CHAIR',
  GET_NETWORK_TOPOLOGY: 'virtualEvent/GET_NETWORK_TOPOLOGY',
  // FILTER_NAVIGATION_SCENE: 'virtualEvent/FILTER_NAVIGATION_SCENE'
}

export default actions
