export async function getLeftMenuData(isVirtualMobileView,navigationData) {
  let isLogout = ''
  if(isVirtualMobileView)
  {
    isLogout = {
      title: 'Logout',
      key: 'logout',
      icon: 'icmn icmn-switch',
    }
  }
  // let thefinalLeg = {}
  // if (isExhibitor) {
  //   thefinalLeg = {
  //     title: 'Leads',
  //     key: 'leads',
  //     icon: 'icmn icmn-user-check',
  //   }
  // }

  // let theDirectory = {}
  // if (!isExhibitor) {
  //   theDirectory = {
  //     title: 'Directory',
  //     key: 'directory',
  //     icon: 'icmn icmn-book',
  //   }
  // }

  console.log("is logout?")
  console.log(isLogout)

  return [
    {
      title: 'Back',
      key: 'back',
      icon: 'icmn icmn-undo2',
    },
    {
      title: 'Home',
      key: 'home',
      icon: 'icmn icmn-home',
    },
    {
      title: 'Chat',
      key: 'sidebar',
      icon: 'icmn icmn-bubbles2',
    },
    {
      title: 'Agenda',
      key: 'agenda',
      icon: 'icmn icmn-calendar',
    },
    // {
    //   title: 'Briefcase',
    //   key: 'briefcase',
    //   icon: 'icmn icmn-briefcase',
    // },
    {
      title: 'Navigation',
      key: 'navigation',
      icon: 'icmn icmn-compass2',
      children: navigationData,
    },
    // {
    //   title: 'Speakers',
    //   key: 'speakers',
    //   icon: 'icmn icmn-man',
    // },
    // {
    //   title: 'Meetings',
    //   key: 'meeting',
    //   icon: 'icmn icmn-user-tie',
    // },
    // theDirectory,
    isLogout,
    // thefinalLeg,
    
  ]
}
export async function getTopMenuData(isVirtualMobileView,navigationData) {
  console.log("in menu top")
  console.log(isVirtualMobileView)
  let isLogout = ''
  if(isVirtualMobileView)
  {
    isLogout = {
      title: 'Logout',
      key: 'logout',
      icon: 'icmn icmn-switch',
    }
  }
  // let thefinalLeg = {}
  // if (isExhibitor) {
  //   thefinalLeg = {
  //     title: 'Leads',
  //     key: 'leads',
  //     icon: 'icmn icmn-user-check',
  //   }
  // }

  // let theDirectory = {}
  // if (!isExhibitor) {
  //   theDirectory = {
  //     title: 'Directory',
  //     key: 'directory',
  //     icon: 'icmn icmn-book',
  //   }
  // }
  return [
    {
      title: 'Back',
      key: 'back',
      icon: 'icmn icmn-undo2',
    },
    {
      title: 'Home',
      key: 'home',
      icon: 'icmn icmn-home',
    },
    {
      title: 'Chat',
      key: 'sidebar',
      icon: 'icmn icmn-bubbles2',
    },
    {
      title: 'Agenda',
      key: 'agenda',
      icon: 'icmn icmn-calendar',
    },
    // {
    //   title: 'Briefcase',
    //   key: 'briefcase',
    //   icon: 'icmn icmn-briefcase',
    // },
    {
      title: 'Navigation',
      key: 'navigation',
      icon: 'icmn icmn-compass2',
      children: navigationData,
    },
    // {
    //   title: 'Speakers',
    //   key: 'speakers',
    //   icon: 'icmn icmn-man',
    // },
    // {
    //   title: 'Meetings',
    //   key: 'meeting',
    //   icon: 'icmn icmn-user-tie',
    // },
    // Object.keys(theDirectory).length !== 0 ? theDirectory : '',
    // thefinalLeg,
    isLogout,
  ]
}
