import React from 'react'
import { Form, Input, Checkbox, Button,Space, Row, Col, Card } from 'antd'
import { connect } from 'react-redux'
import actions from 'redux/virtual/stallconfigurator/actions'
import Preview from './Preview'

/* eslint-disable prefer-promise-reject-errors, consistent-return,prefer-destructuring */

@connect(({ stallConfigurator }) => ({ stallConfigurator }))
class StallDesigner extends React.Component {

  onSubmit = () => {
    const {
      dispatch,
      stallConfigurator: {current}
    } = this.props
    dispatch({
      type: actions.SET_STATE,
      payload: {
        current: current+1
      }
    })
  }

  inputChange = (event) => {
    const {
      dispatch,
    } = this.props
    dispatch({
      type: actions.SET_STATE,
      payload: {
        stallName: event.target.value
      }
    })
  }

  render() {
    const {
      stallConfigurator: { stallName,savingStallDetails },
    } = this.props
    return (
      <div className="paddedModalContent">
        <Row gutter={16}>
          <Col xs={24} md={6} lg={6} xl={6} className="text-center">
            <Preview />
          </Col>
          <Col xs={24} md={18} lg={18} xl={18}>
            <Card className="previewDesign">
              <Form
                name="register"
                onFinish={this.onSubmit}
                scrollToFirstError
                layout="vertical"
                hideRequiredMark
              >
                <Form.Item
                  label="Brand Name"
                  name="stallName"
                  rules={[{ required: true, message: 'Please enter your Stall Name' }]}
                  initialValue={stallName}
                  extra="Once saved, the Brand Name, &amp; the Brand Logo cannot be changed. Kindly enter correct brand name to avoid confusion later."
                >
                  <Input 
                    placeholder="Brand Name that you want to Display" 
                    defaultValue={stallName}
                    data-name="stallName"
                    onChange={this.inputChange}
                    value={stallName}
                  />
                </Form.Item>

                <Form.Item
                  name="confirmagree"
                  valuePropName="checked"
                  rules={[
                    {
                      validator: (_, value) =>
                        value ? Promise.resolve() : Promise.reject('Testimony required.'),
                    },
                  ]}
                >
                  <Checkbox>I understand that the information provided above will be visible to other attendees during the event.</Checkbox>
                </Form.Item>

                <div className="form-actions">
                  
                  <Form.Item>
                    <Space>
                      <Button type="primary" className="width-150 mr-4" htmlType="submit" loading={savingStallDetails}>
                        Save &amp; Continue
                      </Button>
                    </Space>
                  </Form.Item>
                  
                </div>
              </Form>
            </Card>
          </Col>
        </Row>
        
      </div>
    )
  }
}

export default StallDesigner
