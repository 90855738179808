import React, { Suspense, lazy } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
// import Loadable from 'react-loadable'

import Loader from 'components/LayoutComponents/Loader'
import IndexLayout from 'layouts'
import NotFoundPage from 'pages/404'

// const loadable = loader =>
//   Loadable({
//     loader,
//     delay: false,
//     loading: () => <Loader />,
//   })

const routes = [
  {
    path: '/user/login',
    component: lazy(() => import('pages/user/login' /* webpackChunkName: "login" */)),
    exact: true,
  },
  {
    path: '/dashboard/setup-event',
    component: lazy(() =>
      import('pages/virtual/configuration/setup' /* webpackChunkName: "eventsetup" */),
    ),
    exact: true,
  },
  {
    path: '/dashboard/add-agenda',
    component: lazy(() =>
      import('pages/virtual/configuration/agenda' /* webpackChunkName: "addagenda" */),
    ),
    exact: true,
  },
  {
    path: '/dashboard/add-speaker',
    component: lazy(() =>
      import('pages/virtual/configuration/speakers' /* webpackChunkName: "addspeakers" */),
    ),
    exact: true,
  },
  {
    path: '/dashboard/live-notification',
    component: lazy(() =>
      import(
        'pages/virtual/configuration/livenotification' /* webpackChunkName: "livenotification" */
      ),
    ),
    exact: true,
  },
  {
    path: '/dashboard/menu-designer',
    component: lazy(() =>
      import('pages/virtual/configuration/menudesigner' /* webpackChunkName: "menudesigner" */),
    ),
    exact: true,
  },
  {
    path: '/dashboard/analytics',
    component: lazy(() => import('pages/virtual/analytics' /* webpackChunkName: "analytics" */)),
    exact: true,
  },
  {
    path: '/dashboard/stall-designer',
    component: lazy(() =>
      import('pages/virtual/configuration/stalldesigner' /* webpackChunkName: "stalldesigner" */),
    ),
    exact: true,
  },

  // Virtual Event Platform
  // {
  //   path: '/main',
  //   component: lazy(() => import('pages/virtual/landing/' /* webpackChunkName: "landing" */)),
  //   exact: true,
  // },
  {
    path: '/virtual/auth/registerspecial',
    component: lazy(() =>
      import('pages/virtual/event/user/register' /* webpackChunkName: "registervirtual" */),
    ),
    exact: true,
  },
  {
    path: '/virtual/auth/login',
    component: lazy(() =>
      import('pages/virtual/event/user/login' /* webpackChunkName: "loginvirtual" */),
    ),
    exact: true,
  },
  {
    path: '/virtual/auth/forgot',
    component: lazy(() =>
      import('pages/virtual/event/user/forgot' /* webpackChunkName: "forgotvirtual" */),
    ),
    exact: true,
  },
  {
    path: '/virtual/auth/resetpassword',
    component: lazy(() =>
      import('pages/virtual/event/user/resetpassword' /* webpackChunkName: "resetpassword" */),
    ),
    exact: true,
  },
  {
    path: '/virtual/main',
    component: lazy(() =>
      import('pages/virtual/event/main' /* webpackChunkName: "eventmainvirtual" */),
    ),
    exact: true,
  },

  // Dashboards
  {
    path: '/dashboard/alpha',
    component: lazy(() =>
      import('pages/dashboard/alpha' /* webpackChunkName: "dashboardalphavirtual" */),
    ),
  },
]

class Router extends React.Component {
  render() {
    const { history } = this.props
    return (
      <ConnectedRouter history={history}>
        <IndexLayout>
          <Suspense fallback={<Loader />}>
            <Switch>
              <Route exact path="/" render={() => <Redirect to="/virtual/auth/login" />} />
              {routes.map(route => (
                <Route
                  path={route.path}
                  component={route.component}
                  key={route.path}
                  exact={route.exact}
                />
              ))}
              <Route component={NotFoundPage} />
            </Switch>
          </Suspense>
        </IndexLayout>
      </ConnectedRouter>
    )
  }
}

export default Router
