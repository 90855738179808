import actions from './actions'

const initialState = {
  allUsers: {},
  channelData: {},
  requestData: {},
  loadingAttendeeSearch: false,
  initloadingAttendeeList: true,
  loadingAttendeeList: false,
  currentChat: '',
  currentScreen: 'home',
  currentTab: 'attendees',
  pubnub: {},
  listnerInitialized: false,
  visitedExhibitors: {},
  userType: 'attendee',
  notifications: {},
  chatvisitedonce: false,
  maxUsers:25,
  currentPagination:0,
  isSearchResult: false,
  searchPagination: 0,
  searchTerm: ''
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.SET_MESSAGE:
      return {
        ...state,
        channelData: {
          ...state.channelData,
          [action.payload.chatWith]: {
            ...state.channelData[action.payload.chatWith],
            lastTime: action.payload.lastTime,
            readStatus: action.payload.readStatus,
            unreadCount: action.payload.unreadCount,
            messages: state.channelData[action.payload.chatWith].messages.concat(
              action.payload.toAdd,
            ),
          },
        },
      }
    case actions.SET_INSIDE_CHANNEL:
      return {
        ...state,
        channelData: {
          ...state.channelData,
          [action.payload.chatWith]: {
            ...state.channelData[action.payload.chatWith],
            [action.payload.key]: action.payload.value,
          },
        },
      }
    case actions.SET_HISTORY_MESSAGE:
      return {
        ...state,
        channelData: {
          ...state.channelData,
          [action.payload.chatWith]: {
            ...state.channelData[action.payload.chatWith],
            lastTime: action.payload.lastTime,
            messages: action.payload.toAdd,
          },
        },
      }
    case actions.ADD_CHANNEL:
      return {
        ...state,
        channelData: {
          ...state.channelData,
          [action.payload.actualload.chatWith]: action.payload.actualload,
        },
      }
    case actions.SET_NOTIFICATION:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          [action.payload.actualload.message_id]: action.payload.actualload,
        },
      }
    case actions.ADD_REQUESTS:
      return {
        ...state,
        requestData: {
          ...state.requestData,
          [action.payload.actualload.chatWith]: action.payload.actualload,
        },
      }
    default:
      return state
  }
}
