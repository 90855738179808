import React from 'react'
import { Layout } from 'antd'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import classNames from 'classnames'
import Menu from 'components/LayoutComponents/Virtual/Menu'
import Agenda from 'components/LayoutComponents/Virtual/Agenda'
// import Speakers from 'components/LayoutComponents/Virtual/Speakers'
// import Footer from 'components/LayoutComponents/Footer'
// import Breadcrumbs from 'components/LayoutComponents/Breadcrumbs'
import Chat from 'components/LayoutComponents/Virtual/Chat'
// import Briefcase from 'components/LayoutComponents/Virtual/Briefcase'
// import Leads from 'components/LayoutComponents/Virtual/Leads'
// import ThreeDimensionalModel from 'components/LayoutComponents/Virtual/ThreeDimensionalModel'
// import Meetings from 'components/LayoutComponents/Virtual/Meetings'
// import AllMeetings from 'components/LayoutComponents/Virtual/AllMeetings'
// import Directory from 'components/LayoutComponents/Virtual/Directory'
import Photobooth from 'components/LayoutComponents/Virtual/Photobooth'
import SocialWall from  'components/LayoutComponents/Virtual/SocialWall'
// import EditProfile from  'components/LayoutComponents/Virtual/EditProfile'
// import TutorialVideo from 'components/LayoutComponents/Virtual/TutorialVideo'
import StallDesigner from  'components/LayoutComponents/Virtual/StallDesigner'
import ImagePop from  'components/LayoutComponents/Virtual/ImagePop'
import VideoDemand from 'components/LayoutComponents/Virtual/VideoDemand'
import { Beforeunload } from 'react-beforeunload';

const mapStateToProps = ({ virtualSettings }) => ({
  isVirtualMenuTop: virtualSettings.isVirtualMenuTop,
})

@withRouter
@connect(mapStateToProps)
class MainLayout extends React.PureComponent {
  exitPlatform = (event) => {
    console.log("checking exit platform")
    console.log(event)
    event.preventDefault()
    // const { dispatch } = this.props
    // dispatch({
    //   type: 'virtaluser/LOGOUT',
    // })
  }

  render() {
    const { children, isVirtualMenuTop } = this.props
    return (
      <Beforeunload onBeforeunload={this.exitPlatform}>
        <Layout
          className={classNames({
            settings__menuTop: isVirtualMenuTop,
          })}
        >
          <Menu />
          <Chat />
          {/* <Briefcase /> */}
          {/* <Leads /> */}
          <Photobooth />
          {/* <Speakers /> */}
          <ImagePop />
          <VideoDemand />
          <SocialWall />
          {/* <EditProfile /> */}
          {/* <ThreeDimensionalModel /> */}
          {/* <TutorialVideo /> */}
          <StallDesigner />
          {/* <Directory /> */}
          {/* <Meetings /> */}
          {/* <AllMeetings /> */}
          <Agenda />
          <Layout>
            <Layout.Content style={{ height: '100%', position: 'relative' }}>
              {/* <Breadcrumbs /> */}
              <div
                // className="utils__content"
                style={{ padding: '0px' }}
              >
                {children}
              </div>
            </Layout.Content>
            {/* <Layout.Footer>
              <Footer />
            </Layout.Footer> */}
          </Layout>
        </Layout>
      </Beforeunload>
    )
  }
}

export default MainLayout
