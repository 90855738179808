import React from 'react'
import { Row, Col, message, Button, Space } from 'antd'
import { connect } from 'react-redux'
// import { UploadOutlined } from '@ant-design/icons'
import { ExperimentOutlined } from '@ant-design/icons'
import actions from 'redux/virtual/stallconfigurator/actions'
import PlaceholderForm from './PlaceholderForm'
import DocumentDockForm from './DocumentDockForm'
import SocialMediaForm from './SocialMediaForm'
import './customizer.less'
/* eslint-disable prefer-promise-reject-errors, consistent-return,prefer-destructuring */

@connect(({ stallConfigurator }) => ({ stallConfigurator }))
class StallCustomizer extends React.Component {
  constructor(props){
    super()

    props.dispatch({
      type: actions.INITIATE_SCENE,
    })
  }

  beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 0.655; // file size should be less than 655kb
    if (!isLt2M) {
      message.error('Image must smaller than 600kb!');
    }
    return isJpgOrPng && isLt2M;
  }

  fileUpload = ({ file, onSuccess, onProgress, onError, data }) => {
    const { dispatch, currentScene, index } = this.props
    dispatch({
      type: actions.STALL_UPLOAD,
      payload: {
        file,
        clickSectionIndex: index,
        downloadIndex: data,
        uploadtype: 'fileupload',
        onSuccess,
        onError,
        onProgress,
        currentScene,
      },
    })
  }

  saveStall = () => {
    const { dispatch } = this.props
    dispatch({
      type: actions.SAVE_STALL
    })
  }

  render() {
    const {
      stallConfigurator: { scene,savingStall },
    } = this.props
    const theSceneID = Object.keys(scene)[0]
    const sceneLength = Object.keys(scene).length

    let allSSections = {}
    if(sceneLength > 0) 
    {
      allSSections = Object.entries(scene[theSceneID].clickSections).map((key) => {
        console.log("the check")
        console.log(key[1].widget.widgetData)
        if(String(key[1].widget.widgetData.toBeSourced) === "true"){
          const showcase = []
          if(key[1].typeofPod === "downloadable" )
          {
            showcase.push(<DocumentDockForm section={key} />)
          }
          else if(key[1].typeofPod === "socialMedia" )
          {
            showcase.push(<SocialMediaForm section={key} />)
          }
          else{
            showcase.push(<PlaceholderForm section={key} />)
          }

          return (
            <>
              <Row justify="space-around" align="middle">
                <Col span={24}>
                  {showcase}
                </Col>
              </Row>
              <br />
            </>
          )
        }
        return ''
      })
    }

    console.log("looking inside")
    console.log(scene)
    console.log(scene.imageUrl)
    return (
      <div>
        <Row justify="space-around" align="middle" className="paddedModalContent">
          <Col span={12}>
            {sceneLength > 0 ? allSSections : ''}
          </Col>
          <Col span={12} className="text-center">
            <h3>The Selected Stall</h3>
            <br />
            {sceneLength > 0 ? <img src={scene[theSceneID].imageUrl} alt={theSceneID} style={{width: "90%"}} /> : ""}
          </Col>
        </Row>
        <Row className="thefooter">
          <Col span={24} className="text-center">
            <Space>
              {/* <Button 
                type="primary" 
                danger 
                icon={<ExperimentOutlined />} 
                onClick={this.saveStall}
              >
                Save Stall Configuration
              </Button> */}
              <Button 
                type="primary" 
                // danger 
                icon={<ExperimentOutlined />} 
                onClick={this.saveStall}
                loading={savingStall}
              >
                Save &amp; Preview
              </Button>
            </Space>
            
          </Col>
        </Row>

        
      </div>
      // <>
      // </>
    )
  }
}

export default StallCustomizer
