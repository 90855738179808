import axios from 'axios'

/* eslint-disable no-unused-vars */
const superlocalURL = `https://localhost:443/api/`
const semiProductionURL = `https://2acbb4acca0a.ngrok.io/api/`
const productionURL = `https://virtual.wiz365.io/api/`
const ikmcproductURL = `https://ikmc.wiz365.io/api`
export default axios.create({
  baseURL: ikmcproductURL,
})
