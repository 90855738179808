import React from 'react'
import { Button, Row, Col, Skeleton } from 'antd'
import { connect } from 'react-redux'
import actions from 'redux/virtual/stallconfigurator/actions'

/* eslint-disable prefer-promise-reject-errors, consistent-return,prefer-destructuring */

@connect(({ stallConfigurator, virtualUser }) => ({ stallConfigurator, virtualUser }))
class StallSelector extends React.Component {
  constructor(props)
  {
    super()
    props.dispatch({
      type: actions.SET_STATE,
        payload: {
          stallsLoading: true,
        },
    })
    props.dispatch({
      type: actions.GET_STALL_OPTIONS,
      payload: {},
    })
  }


  chooseStall = (event) =>{
    const {dispatch,stallConfigurator:{current,stalls}} = this.props
    const id = event.target.getAttribute('data-id')

    dispatch({
      type: actions.SET_STATE,
        payload: {
          current: current +1,
          selectedStall: stalls[id]
        },
    })
  }

  render() {
    const {
      stallConfigurator: { stalls,stallsLoading },
      virtualUser: {roles}
    } = this.props
    console.log("Checking Stalls")
    console.log(stalls)

    // const totalStall = Object.keys(stalls).length
    // const colNumber = Math.ceil(24 / totalStall)
    const colNumber = 8
    const allStalls = Object.entries(stalls).map((key) => {
      let rolesAllowed = key[1].roles
      if(typeof rolesAllowed === "string")
      {
        rolesAllowed = rolesAllowed.substring(1, rolesAllowed.length-1).replace(/ /g, '').split(",");
      }

      let isPermitted = false
      rolesAllowed.forEach(element => {
        if(roles.includes(element))
        {
          isPermitted = true
        }
      });

      if(isPermitted)
      {
        return (
          <Col span={colNumber} className="text-center">
            <img src={key[1].imageUrl} alt={key[0]} style={{width: "90%"}} />
            <br />
            <h4>{key[1].name}</h4>
            <Button type="primary" data-id={key[0]} onClick={this.chooseStall}>Choose This Stall</Button>
          </Col>
        )
      }
      return ''
      
    })
    return (
      <div className="paddedModalContent">
        <Skeleton loading={stallsLoading} active>
          <Row gutter={16} justify="space-around" align="middle">
            {allStalls}
          </Row>
        </Skeleton>
        
      </div>
      // <>
      // </>
    )
  }
}

export default StallSelector
