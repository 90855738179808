import React from 'react'
import { Button, Avatar,Card, Row, Col, Tooltip,Upload, message } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { connect } from 'react-redux'
import actions from 'redux/virtual/stallconfigurator/actions'
// import styles from '../style.module.scss'
import './preview.less'

/* eslint-disable prefer-promise-reject-errors, prefer-template */

@connect(({ stallConfigurator }) => ({ stallConfigurator }))
class AvatarForm extends React.Component {

  avatarUpload = ({ file, onSuccess, onProgress, onError }) => {
    const { dispatch } = this.props
    dispatch({
      type: actions.EDIT_BRAND_LOGO,
      payload: {
        file,
        onSuccess,
        onError,
        onProgress,
      },
    })
  }

  beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 0.655; // file size should be less than 655kb
    if (!isLt2M) {
      message.error('Image must smaller than 600kb!');
    }
    return isJpgOrPng && isLt2M;
  }

  render() {
    // const { currentIndexOption, currentIndexColor } = this.state
    const {
      stallConfigurator: { stallLogo },
    } = this.props
    return (
      <div>
        <Card className="previewDesign">
          <Row justify="space-around" align="middle">
            <Col span={24} className="text-center">
              <h4>Brand Logo</h4>
            </Col>
          </Row>
          <br />
          <Row justify="space-around" align="middle">
            <Col span={24} className="text-center">
              <Avatar src={stallLogo} size={100} />
            </Col>
          </Row>
          <br />
          <Row>
            <Col span={24}>
              <Upload 
                beforeUpload={this.beforeUpload}
                customRequest={this.avatarUpload}
              >
                <Tooltip title="Upload Picture">
                  <Button>
                    <UploadOutlined /> Click to Replace
                  </Button>
                </Tooltip>
              </Upload>
            </Col>
          </Row>
          <br />
          <Row>
            <Col span={24} className="text-left">
              <h6>Upload Guidelines:</h6>
              <ol>
                <li>Only jpg / png images are allowed</li>
                <li>File Size should be less than 600kb</li>
                <li>Place your logo inside a square image (Preferred: 300px x 300px @ 300dpi) and upload it for optimal results</li>
              </ol>
              
            </Col>
          </Row>
        </Card>

      </div>
    )
  }
}

export default AvatarForm
