import actions from './actions'

const initialState = {
  id: '',
  name: '',
  roles: [],
  number: '',
  email: '',
  organization: '',
  designation: '',
  avatarType: 'text',
  avatarData: '',
  avatarColor: '',
  mode: 'email',
  authorized: false,
  loading: false,
  loginStatus: '',
  accessToken: '',
  tokenType: '',
  assignedScene: [],
  forgotLoading: false,
  resetLoading: true,
  isTutorialVideoVisible: false,
  options: [],
  colors: ['#f56a00', '#7265e6', '#ffbf00', '#00a2ae'],
  isEditProfileOpen: false
}

export default function virtualuserReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
