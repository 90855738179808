import { all, takeEvery, call, put, select } from 'redux-saga/effects'
import { notification, message } from 'antd'
import { login, getUser, logout, forgotPassword,resetPassword,editProfile } from 'services/virtual/user'
import { uploadFile } from 'services/virtual/general'
import capitalizeFirstLetters from 'capitalize-first-letters'
import streamActions from 'redux/virtual/streamfeature/actions'
import stallConfigActions from 'redux/virtual/stallconfigurator/actions'
import actions from './actions'

// import eventActions from '../event/actions'
import menuActions from '../menu/actions'

export function* LOGIN(data) {
  try {
    const result = yield call(login, { finalData: data.payload })
    if (result.status === 200) {
      if (result.data.active_status) {
        if (!result.data.kick_out) {
          if (!result.data.login_status) {
            if (result.data.payment_status) {
              localStorage.setItem('acctoken', result.data.accessToken)
              localStorage.setItem('userid', result.data.id)
              localStorage.setItem('tokenType', result.data.tokenType)
              yield put({
                type: actions.LOGIN_HELPER,
                payload: {
                  accessToken: result.data.accessToken,
                  id: result.data.id,
                  tokenType: result.data.tokenType,
                },
              })
            } else {
              notification.error({
                message: 'Trouble with account',
                duration: 12,
                description:
                  'Your account is not yet approved. It could be because of incomplete registration process. Kindly contact the admin right now.',
              })
              localStorage.clear()
              yield put({
                type: actions.SET_STATE,
                payload: {
                  loading: false,
                },
              })
            }
          } else {
            notification.error({
              message: 'Multiple Session Detected',
              duration: 12,
              description:
                'Your account is logged in another device. Kindly logout from the other device to login here. If you think this is an issue, kindly contact the admin or email us at support@wiz365.io',
            })
            localStorage.clear()
            yield put({
              type: actions.SET_STATE,
              payload: {
                loading: false,
              },
            })
          }
        } else {
          notification.error({
            message: 'You Account has been blocked',
            duration: 12,
            description:
              'The account has been blocked by the admin. It could be due to voilation of our policies. Kindly contact the admin now.',
          })
          localStorage.clear()
          yield put({
            type: actions.SET_STATE,
            payload: {
              loading: false,
            },
          })
        }
      } else {
        notification.error({
          message: "We haven't started yet.",
          duration: 12,
          description: "IKMC 2020 is Scheduled for 28th & 29th October. We'll see you there.",
        })
        localStorage.clear()
        yield put({
          type: actions.SET_STATE,
          payload: {
            loading: false,
          },
        })
      }
    } else {
      notification.error({
        message: 'Status Invalid',
        duration: 12,
        description:
          'The details entered for logging in are either incorrect or the user is not registered.',
      })
      localStorage.clear()
      yield put({
        type: actions.SET_STATE,
        payload: {
          loading: false,
        },
      })
    }
  } catch (err) {
    console.log(err.response)
    const result = err.response
    if (result.status === 401) {
      notification.error({
        message: 'Unauthorized Access',
        description:
          'The details entered for logging in are either incorrect or the user is not registered.',
      })

      yield put({
        type: actions.SET_STATE,
        payload: {
          loading: false,
        },
      })
    }
  }
}

export function* LOGIN_HELPER(data) {
  try {
    const detailResult = yield call(getUser, {
      id: data.payload.id,
      token: data.payload.accessToken,
    })


    if (detailResult.status === 200) {
      notification.success({
        message: 'Access Granted',
        description: 'Welcome to IKMC 2020.',
      })

      // yield put({
      //   type: actions.SET_STATE,
      //   payload: {
      //     isTutorialVideoVisible: true
      //   }
      // })

      // yield put({
      //   type: actions.SET_STATE,
      //   payload: {
      //     isEditProfileOpen: true
      //   }
      // })

      if(detailResult.data.roles.includes('ROLE_STALL_CONFIGURATOR'))
      {
        yield put({
          type: stallConfigActions.SET_STATE,
          payload: {
            isStallDesignerOpen: true
          }
        })
      }
      

      yield put({
        type: actions.SET_STATE,
        payload: {
          id: data.payload.id,
          name: capitalizeFirstLetters(detailResult.data.name),
          number: `+${detailResult.data.number}`,
          roles: detailResult.data.roles,
          email: detailResult.data.email,
          organization: capitalizeFirstLetters(detailResult.data.organization),
          designation: capitalizeFirstLetters(detailResult.data.designation),
          avatarType: detailResult.data.avatar_type,
          avatarData: detailResult.data.avatar_data,
          avatarColor: detailResult.data.avatar_color,
          accessToken: data.payload.accessToken,
          tokenType: data.payload.tokenType,
          assignedScene: detailResult.data.assignedScene,
          authorized: true,
          loading: false,
        },
      })

      yield put({
        type: menuActions.GET_DATA,
      })

      yield put({
        type: streamActions.SET_STATE,
        payload: {
          assignedSessions: detailResult.data.assignedSessions,
        },
      })
    } else {
      notification.error({
        message: 'Error Fetching Details',
        description: 'There is an issue with your account, kindly contact the administrator.',
      })
    }
  } catch (err) {
    console.log(err.response)
    const result = err.response
    if (result.status === 401) {
      notification.error({
        message: 'Unauthorized Access',
        duration: 12,
        description:
          'The details entered for logging in are either incorrect or the user is not registered.',
      })

      yield put({
        type: actions.SET_STATE,
        payload: {
          loading: false,
        },
      })
    }
  }
}

export function* LOGOUT() {
  // yield call(logout)
  localStorage.clear()
  const state = yield select()
  const {
    virtualUser: { accessToken },
  } = state

  yield call(logout, {
    token: accessToken,
  })

  yield put({
    type: actions.SET_STATE,
    payload: {
      id: '',
      name: '',
      number: '',
      roles: '',
      email: '',
      organization: '',
      designation: '',
      avatarType: '',
      avatarData: '',
      avatarColor: '',
      accessToken: '',
      tokenType: '',
      assignedScene: '',
      authorized: false,
      loading: false,
    },
  })
}

export function* RESET_PASSWORD(data) {
  try {
    const result = yield call(resetPassword, {
      id: data.payload.id,
    })
    if(result.status === 200)
    {
      console.log(result)
      yield put({
        type: actions.SET_STATE,
        payload: {
          resetLoading: false
        },
      })
      notification.success({
        message: 'Password Reset is now successful',
        duration: 12,
        description:
          "Kindly login using the password shown on the screen.",
      })
    }

  } catch (err) {
    console.log(err.response)
    const result = err.response
    if (result.status === 401) {
      notification.error({
        message: 'Password Reset Unsuccessful',
        duration: 12,
        description:
          "Kindly write to us at support@ikmc2020.com",
      })

      yield put({
        type: actions.SET_STATE,
        payload: {
          forgotLoading: false,
        },
      })
    }
  }
}

export function* FORGOT_PASSWORD(data) {
  try {
    const result = yield call(forgotPassword, {
      userDetails: data.payload.userDetails,
    })
    if(result.status === 200)
    {
      console.log(result)
      yield put({
        type: actions.SET_STATE,
        payload: {
          forgotLoading: false
        },
      })
      notification.success({
        message: 'Email Successfully sent',
        duration: 12,
        description:
          "Kindly check your email for password recovery link. Don't forget to check your inbox, promotions tab, updates tab or spam for the email.",
      })
    }

  } catch (err) {
    console.log(err.response)
    const result = err.response
    if (result.status === 401) {
      notification.error({
        message: 'User not found with this User ID / Email ID',
        duration: 12,
        description:
          "We could not find a user registered with mentioned Email ID / User ID. Kindly write to us at support@ikmc2020.com.",
      })

      yield put({
        type: actions.SET_STATE,
        payload: {
          forgotLoading: false,
        },
      })
    }
  }
}

export function* EDIT_AVATAR(data) {
  try {
    const config = {
      onUploadProgress: progressEvent => {
        const percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total)
        data.payload.onProgress({ percent: percentCompleted })
      },
    }
    const result = yield call(uploadFile, { file: data.payload.file, config })
    if (result.status === 200) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          avatarType: 'image',
          avatarData: result.data.fileUrl,
        },
      })
      data.payload.onSuccess('ok')
      message.success('Image Uploaded Successfully')
    } else {
      data.payload.onError({ event: result.statusText })
      message.error(
        'Error occured in uploading the Image. Kindly Choose a normal Avatar as of now.',
      )
    }
  } catch (err) {
    data.payload.onError({ event: err.response.data.message })
    console.log(err.response)
    message.error(err.response.data.message)
  }
}


export function* COMPLETE_EDIT_PROFILE() {
  try {
    const state = yield select()
    const {
      virtualUser: {
        name,
        avatarType,
        avatarData,
        avatarColor,
        accessToken
      },
    } = state
    const finalValue = {
      name,
      avatarType,
      avatarData,
      avatarColor,
    }
    yield put({
      type: actions.SET_STATE,
      payload: {
        editProfileLoading: true
      }
    })
    const result = yield call(editProfile, { finalData: finalValue, token: accessToken })
    if (result.status === 200) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          isEditProfileOpen: false,
          editProfileLoading: false,
          isTutorialVideoVisible: true
        }
      })
      message.success('Profile Edited Succesfuly')
    } else {
      message.error(`${result.status}: ${result.statusText} - Error Occurred`)
    }
  } catch (err) {
    console.log(err.response)
    message.error(err.response.data.message)
  }
}


export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOGIN, LOGIN),
    takeEvery(actions.LOGIN_HELPER, LOGIN_HELPER),
    takeEvery(actions.LOGOUT, LOGOUT),
    takeEvery(actions.FORGOT_PASSWORD, FORGOT_PASSWORD),
    takeEvery(actions.RESET_PASSWORD, RESET_PASSWORD),
    takeEvery(actions.EDIT_AVATAR, EDIT_AVATAR),
    takeEvery(actions.COMPLETE_EDIT_PROFILE, COMPLETE_EDIT_PROFILE)
  ])
}
