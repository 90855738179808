import React from 'react'
import { Row, Col, Button } from 'antd'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { connect } from 'react-redux'
import actions from 'redux/virtual/photobooth/actions'

const mapStateToProps = ({ photobooth }) => ({ photobooth })

@connect(mapStateToProps)
class Showcase extends React.Component {
  retake = () => {
    const {
      dispatch,
      photobooth: { currentStep },
    } = this.props
    dispatch({
      type: actions.SET_STATE,
      payload: {
        currentStep: currentStep - 1,
        imageSrc: '',
      },
    })
  }

  // goNext = () => {
  //   const {
  //     dispatch,
  //     photobooth: { currentStep },
  //   } = this.props
  //   dispatch({
  //     type: actions.SET_STATE,
  //     payload: {
  //       currentStep: currentStep + 1,
  //     },
  //   })
  //   dispatch({
  //     type: actions.PHOTOBOOTH_ANALYTICS
  //   })
  // }



  goNext = () => {
    const {
      dispatch,
      photobooth: { currentStep, imageSrc },
    } = this.props

    const canvas = document.createElement('canvas')
    canvas.id = 'overlayImage'
    canvas.width = 1920
    canvas.height = 1080
    const ctx = canvas.getContext('2d')

    const image = new Image()
    image.onload = function() {
      ctx.drawImage(image, 587, 137,755,755)
    }
    image.src = imageSrc

    const overlayImage = new Image()
    overlayImage.src = 'resources/images/overlay/overlay01.png'
    overlayImage.onload = () => {
      ctx.drawImage(overlayImage, 0, 0)
      const finalImage = canvas.toDataURL()
      dispatch({
        type: actions.SET_STATE,
        payload: {
          imageSrc: finalImage,
        },
      })
    }
    dispatch({
      type: actions.SET_STATE,
      payload: {
        currentStep: currentStep + 1,
      },
    })
    dispatch({
      type: actions.PHOTOBOOTH_ANALYTICS
    })
  }
  

  render() {
    const {
      photobooth: { imageSrc },
    } = this.props
    return (
      <>
        <Row justify="space-around" align="middle" className="text-center">
          <Col span={24} className="text-center">
            <img src={imageSrc} alt="newImage" style={{ width: '100%', borderRadius: '15px 15px 0 0' }} />
          </Col>
        </Row>
        <Row justify="space-around" align="middle" style={{ marginTop: '15px' }}>
          <Col span={24} className="text-center">
            <h3>Looking Awesome!</h3>
          </Col>
        </Row>
        <Row justify="space-around" align="middle" style={{ marginTop: '15px' }}>
          <Col span={24} className="text-center">
            <Button shape="circle" onClick={this.retake} size="large">
              Retake
            </Button>
          </Col>
        </Row>
        <Row justify="space-around" align="middle" style={{ marginTop: '15px' }}>
          <Col span={8} className="text-center">
            <Button type="text" onClick={this.retake} size="medium">
              <LeftOutlined /> &nbsp; Back
            </Button>
          </Col>
          <Col span={8} className="text-center" />
          <Col span={8} className="text-center">
            <Button type="primary" onClick={this.goNext} size="medium">
              Next &nbsp; <RightOutlined />
            </Button>
          </Col>
        </Row>
      </>
    )
  }
}

export default Showcase
