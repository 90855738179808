import { all, call, takeEvery, select } from 'redux-saga/effects'
import { updateWidgetAnalytics } from 'services/virtual/event'
import { message } from 'antd'
import actions from './actions'

export function* PHOTOBOOTH_ANALYTICS() {
  try {
    const state = yield select()
    const {
      virtualUser: { accessToken },
    } = state
    yield call(updateWidgetAnalytics, { id: '1234', token: accessToken })
  } catch (err) {
    console.log(err)
    message.error(`${err.response.status}:${err.response.data.message}`)
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.PHOTOBOOTH_ANALYTICS, PHOTOBOOTH_ANALYTICS)
    // takeEvery(actions.GET_AGENDA, GET_AGENDA)
  ])
}
