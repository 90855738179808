import React from 'react'
import { message, Card, Upload, Tooltip, Button, Row, Col, Input } from 'antd'
import { connect } from 'react-redux'
import { UploadOutlined } from '@ant-design/icons'
import uuid from 'react-uuid'
import actions from 'redux/virtual/stallconfigurator/actions'

const { TextArea } = Input
/* eslint-disable prefer-promise-reject-errors, consistent-return,prefer-destructuring */

const mediaTypes = {
  "jpg": { mediaType : "image/jpeg", size: 2 },
  "jpeg": { mediaType : "image/jpeg", size: 2 },
  "png": { mediaType : "image/png", size: 2 },
  "gif": { mediaType : "image/gif", size: 2 },
  "pdf": { mediaType : "application/pdf", size: 5 },
  "mp4": { mediaType : "video/mp4", size: 100 },
}

@connect(({ stallConfigurator }) => ({ stallConfigurator }))
class DocumentDockForm extends React.Component {

  beforeUpload = (file) => {
    const {
      section
    } = this.props

    const extension = file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase()

    let fileFormatPermitted = section[1].widget.widgetData.fileFormatPermitted
    console.log("looking for permitted")
    console.log(fileFormatPermitted)
    if(typeof fileFormatPermitted === "string")
    {
      fileFormatPermitted = fileFormatPermitted.substring(1, fileFormatPermitted.length-1).replace(/ /g, '').split(",");
    }
    const theFilePermittedArray = []
    fileFormatPermitted.forEach(element => {
      if (Object.prototype.hasOwnProperty.call(mediaTypes, element)) {
        theFilePermittedArray.push(mediaTypes[element].mediaType)
      }
    });
    console.log(theFilePermittedArray)
    const isPermitted = theFilePermittedArray.includes(file.type)
    console.log(isPermitted)
    if (!isPermitted) {
      message.error(`File format not supported. This section only accepts the following formats: ${section[1].widget.widgetData.fileFormatPermitted.toString()} `);
    }

    let fileSizeAllowed = false
    if (Object.prototype.hasOwnProperty.call(mediaTypes, extension)) {
      fileSizeAllowed = file.size / 1024 / 1024 < mediaTypes[extension].size; // file size should be less than 655kb
      if (!fileSizeAllowed) {
        message.error(`File must smaller than ${mediaTypes[extension].size}mb!!`);
      }
    }
    else{
      message.error(`File format not supported. This section only accepts the following formats: ${section[1].widget.widgetData.fileFormatPermitted.toString()} `);
    }
    
    return isPermitted && fileSizeAllowed;
  }

  fileUpload = ({ file, onSuccess, onProgress, onError, data }) => {
    const { dispatch, currentScene, index } = this.props
    dispatch({
      type: actions.STALL_UPLOAD,
      payload: {
        file,
        clickSectionIndex: index,
        downloadIndex: data,
        uploadtype: 'documentupload',
        onSuccess,
        onError,
        onProgress,
        currentScene,
      },
    })
  }

  inputChange = (event) => {
    const id = event.target.getAttribute('data-id')
    const inputName = event.target.getAttribute('data-name')
    const {
      dispatch,
      stallConfigurator: {allDocuments}
    } = this.props
    const tempDocuments = allDocuments
    tempDocuments[id][inputName] = event.target.value
    dispatch({
      type: actions.SET_STATE,
      payload:{
        allDocuments: tempDocuments
      }
    })
  }

  addDocument = () => {
    const {
      dispatch,
      stallConfigurator: {allDocuments, currentDocument}
    } = this.props
    const tempDocuments = allDocuments
    tempDocuments[uuid()] = {
      heading: "",
      description: "",
      fileURL: ""
    }
    dispatch({
      type: actions.SET_STATE,
      payload:{
        allDocuments: tempDocuments,
        currentDocument: currentDocument + 1
      }
    })

  }

  render() {
    const {
      section,
      stallConfigurator: {currentDocument,totalDocuments,allDocuments}
    } = this.props

    const documentShowcase = Object.entries(allDocuments).map(key => {
      return (
        <>
          <Card type="inner" title={`Document #${currentDocument}`}>
            <Row>
              <Col span={24}>
                <div className="ant-row ant-form-item">
                  <div className="ant-col ant-form-item-control">
                    <div className="ant-form-item-control-input">
                      <div className="ant-form-item-control-input-content">
                        <span>File Heading &nbsp;&nbsp;&nbsp;</span>
                        <Input 
                          placeholder="Heading of the File" 
                          data-name="heading"
                          data-id={key[0]}
                          onChange={this.inputChange}
                          value={key[1].heading}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <div className="ant-row ant-form-item">
                  <div className="ant-col ant-form-item-control">
                    <div className="ant-form-item-control-input">
                      <div className="ant-form-item-control-input-content">
                        <span>Description &nbsp;&nbsp;&nbsp;</span>
                        <TextArea
                          placeholder="Enter the file description"
                          autoSize={{ minRows: 2, maxRows: 6 }}
                          data-name="description"
                          data-id={key[0]}
                          onChange={this.inputChange}
                          value={key[1].description}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <div className="ant-row ant-form-item">
                  <div className="ant-col ant-form-item-control">
                    <div className="ant-form-item-control-input">
                      <div className="ant-form-item-control-input-content">
                        <span>Upload File &nbsp;&nbsp;&nbsp;</span>
                        <Upload 
                          beforeUpload={this.beforeUpload}
                          customRequest={this.fileUpload}
                          data={key[0]}
                        >
                          <Tooltip title="Upload Asset">
                            <Button>
                              <UploadOutlined /> Click to Upload
                            </Button>
                          </Tooltip>
                        </Upload>
                        <br />
                        {key[1].fileURL !== "" ? <a href={key[1].fileURL} target="_blank" rel="noopener noreferrer">Download File</a>: ""}
                        {/* <p>{downloadSection.fileURL}</p> */}
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Card>
          <br />
        </>
      )
    })
    return (
      <Card title={section[1].name} style={{ width: "90%" }}>
        <p>{section[1].widget.widgetData.extraMessage}</p>
        {documentShowcase}
        {currentDocument + 1 <= totalDocuments ? <Button type="primary" onClick={this.addDocument}>Add Document</Button> : "" }
      </Card>
      // <>
      // </>
    )
  }
}

export default DocumentDockForm
